import React, { useEffect, useState } from "react";
import songService from "../services/songService"; // Ensure the path matches your project structure
import SongItem from "./SongItem";

const SongList = ({ onSelectSong }) => {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSongs = async () => {
      setLoading(true);
      try {
        const songs = await songService.fetchSongs();
        setSongs(songs);
      } catch (error) {
        setError("Oops! Failed to fetch songs...");
        console.error("Error fetching songs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSongs();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="pb-10">
      {songs.map((song) => (
        <SongItem key={song.id} song={song} onSelect={onSelectSong} />
      ))}
    </div>
  );
};

export default SongList;
