import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import VotePage from "./pages/VotePage";
import { SongProvider } from "./context/SongContext";
import { PhoneNumberProvider } from "./context/PhoneNumberContext";
import PaymentPage from "./pages/PaymentPage";
import PhoneVerificationPage from "./pages/PhoneVerificationPage";
import "./App.css";

const App = () => (
  <Router>
    <SongProvider>
      <PhoneNumberProvider>
        <Routes>
          <Route path="/" element={<VotePage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route
            path="/phoneVerification"
            element={<PhoneVerificationPage />}
          />
          <Route path="/success" element={<h1>Payment Successful!</h1>} />
        </Routes>
      </PhoneNumberProvider>
    </SongProvider>
  </Router>
);

export default App;
