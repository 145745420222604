import React, { useState, useEffect } from "react";
import { useSong } from "../context/SongContext";
import { usePhone } from "../context/PhoneNumberContext";
import paymentService from "../services/paymentService";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/spinner";

const PaymentPage = () => {
  const { selectedSong } = useSong();
  const { selectedPhone } = usePhone();
  const navigate = useNavigate();

  const [lynkId, setLynkId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Cleanup function to clear the timeout when the component unmounts or dependencies change
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  // Check if the song is available
  if (!selectedSong) {
    navigate("/");
    return <p>No song selected. Please select a song first.</p>;
  }

  //Check if phone was verified
  if (!selectedPhone) {
    navigate("/");
    return <p>Please enter a phone number first</p>;
  }

  const handlePayment = async () => {
    setIsLoading(true);
    setErrorMessage("");
    // Prepare the payload
    const payload = {
      lynkId: lynkId,
      phoneNumber: selectedPhone,
      productId: selectedSong.id,
    };

    try {
      // Make the POST request to the backend
      const response = await paymentService.makePayment(payload);
      console.log("Payment response:", response.data); // log or handle response data as needed
      setIsLoading(false);
      setPaymentMessage(
        "Please approve the payment in your Lynk App to enable your vote."
      );

      const id = setTimeout(() => {
        navigate("/");
      }, 10000);
      setTimeoutId(id);
    } catch (error) {
      console.error("Payment failed:", error);
      setIsLoading(false);
      setErrorMessage("Payment failed, please try again.");
    }
  };

  const goBack = (song) => {
    navigate("/");
  };

  return (
    <div>
      {paymentMessage ? (
        <div className="flex flex-col items-center justify-center h-screen oswald-400">
          <p>{paymentMessage}</p>
          <button
            className="mt-2 bg-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded"
            onClick={goBack}
          >
            Back to selection
          </button>
        </div>
      ) : (
        <div>
          <div className="flex flex-col items-center justify-center h-screen oswald-400">
            <h2 className="text-3xl mb-4">
              Make Payment for: {selectedSong.name}
            </h2>
            <label className="mb-4 text-gray-500 flex items-center">
              <span className="mr-3">Your LynkID:</span>
              <input
                type="text"
                value={lynkId}
                onChange={(e) => setLynkId(e.target.value)}
                placeholder="@your-lynkId"
                disabled={isLoading}
                className="mt-1 py-2 px-3"
              />
            </label>
            <h1 className="text-xl text-red-500">{errorMessage}</h1>
            {isLoading ? (
              <Spinner />
            ) : (
              <button
                className="mt-2 bg-green-400 hover:bg-green-300 text-white font-bold py-2 px-4 rounded"
                onClick={handlePayment}
                disabled={isLoading || !lynkId}
              >
                Pay JMD $25.00 with Lynk
              </button>
            )}
            <button
              className="mt-2 bg-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded"
              onClick={goBack}
            >
              Back to selection
            </button>
          </div>
        </div>
      )}

      <div></div>
    </div>
  );
};

export default PaymentPage;
