import React, { createContext, useContext, useState } from 'react';

const PhoneNumberContext = createContext(null);

export const usePhone = () => useContext(PhoneNumberContext);

export const PhoneNumberProvider = ({ children }) => {
  const [selectedPhone, setSelectedPhone] = useState(null);

  return (
    <PhoneNumberContext.Provider value={{ selectedPhone, setSelectedPhone }}>
      {children}
    </PhoneNumberContext.Provider>
  );
};

