// src/services/songService.js
import axios from "axios";
import authService from "./authService";

const API_URL = process.env.REACT_APP_API_URL + "/api/songs";

const fetchSongs = async () => {
  let token = await authService.getAuthToken();

  try {
    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 503)
    ) {
      // Token might be expired or service unavailable, refresh the token
      try {
        token = await authService.refreshAuthToken();
        const retryResponse = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return retryResponse.data;
      } catch (retryError) {
        console.error("Retry after token refresh failed:", retryError);
        throw retryError;
      }
    } else {
      throw error;
    }
  }
};

export default {
  fetchSongs,
};
