import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SongList from "../components/SongList";
import { useSong } from "../context/SongContext";

const VotePage = () => {
  const navigate = useNavigate();
  const { setSelectedSong } = useSong();
  const [didReadInstructions, setDidReadInstructions] = useState(false);

  const handleSelectSong = (song) => {
    setSelectedSong(song);
    navigate("/phoneVerification");
  };

  return !didReadInstructions ? (
    <div className="flex flex-col items-center justify-center h-screen oswald-400">
      <h1 className="text-3xl text-gray-500 oswald-600">Song Contest</h1>
      <p className="text-xl text-center mt-4 oswald-300">
        Choose your favorite song from the list of ten winning songs provided.
        <br />
        Then pay $25.00 from your Lynk account
        <br /> and you could win $50,000 if you are one of the first two
        contestants randomly selected at the end of the contest.
      </p>
      <p className="text-xl text-center mt-4 oswald-300">
        The competition begins July 23 and ends August 5, 2024.
      </p>
      <p className="text-xl text-center mt-4 oswald-300">
        Enter as often as you like!
      </p>
      <button
        className="mt-6 bg-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded oswald-400"
        onClick={() => setDidReadInstructions(true)}
      >
        Begin
      </button>
    </div>
  ) : (
    <div className="oswald-400">
      <h1 className="text-3xl pb-3 oswald-600">Select a Song</h1>
      <SongList onSelectSong={handleSelectSong} />
    </div>
  );
};

export default VotePage;
