import React, { createContext, useContext, useState } from 'react';

const SongContext = createContext(null);

export const useSong = () => useContext(SongContext);

export const SongProvider = ({ children }) => {
  const [selectedSong, setSelectedSong] = useState(null);

  return (
    <SongContext.Provider value={{ selectedSong, setSelectedSong }}>
      {children}
    </SongContext.Provider>
  );
};

