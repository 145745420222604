import React, { useState } from "react";
import verificationService from "../services/phoneVerificationService";
import { useNavigate } from "react-router-dom";
import { usePhone } from "../context/PhoneNumberContext";
import Spinner from "../components/spinner";

const PhoneVerificationPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();
  const { setSelectedPhone } = usePhone();
  const { selectedPhone } = usePhone();

  const requestCode = async () => {
    setLoading(true);
    setMessage("");
    try {
      await verificationService.requestVerificationCode(phoneNumber);
      setMessage("Verification code sent to your phone.");
      setCodeSent(true);
    } catch (error) {
      console.error("Error requesting verification code:", error);
      setMessage("Failed to send verification code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const verifyCode = async () => {
    setLoading(true);
    setMessage("");
    try {
      await verificationService.verifyCode(phoneNumber, verificationCode);
      setMessage("Phone number verified successfully.");
      setSelectedPhone(phoneNumber);
      navigate("/payment");
    } catch (error) {
      console.error("Error requesting verification code:", error);
      setMessage("Invalid verification code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const newPhoneNumber = () => {
    setSelectedPhone(null);
    setPhoneNumber("");
    setCodeSent(false);
  };

  if (selectedPhone && !codeSent) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h3 className="text-3xl text-gray-500">
          Using current phone number: {selectedPhone}
        </h3>
        <button
          className="mt-2 bg-green-500 hover:bg-green-100 text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            navigate("/payment");
          }}
        >
          Continue to payment
        </button>
        <button
          className="mt-2 bg-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded"
          onClick={newPhoneNumber}
        >
          Edit Phone Number
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen oswald-400">
      <h1 className="text-3xl mb-4">Phone Verification</h1>
      <h3 className="text-l text-gray-500">
        Please enter your WhatsApp telephone number
      </h3>
      <h3 className="text-l text-gray-500 mb-4">
        to receive a verification code
      </h3>
      {!codeSent ? (
        <div className="flex flex-col items-center oswald-400">
          <label className="mb-4 text-gray-500 flex items-center">
            <span className="mr-3">Phone Number:</span>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              disabled={loading}
              placeholder="e.g: 18761234567"
              className="mt-1 py-2 px-3"
            />
          </label>

          {loading ? (
            <Spinner />
          ) : (
            <button
              className="mt-2 bg-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded" // Reduced margin-top
              onClick={requestCode}
              disabled={loading || !phoneNumber}
            >
              Request Code
            </button>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center mt-4">
          <label className="mb-4 text-gray-500 flex items-center">
            <span className="mr-3">Verification Code:</span>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              disabled={loading}
              className="mt-1 py-2 px-3"
            />
          </label>
          <button
            className="bg-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded"
            onClick={verifyCode}
            disabled={loading || !verificationCode}
          >
            {loading ? "Verifying..." : "Verify Code"}
          </button>
        </div>
      )}
      {message && <p className="mt-3">{message}</p>}
    </div>
  );
};

export default PhoneVerificationPage;
