import React from 'react';

const SongItem = ({ song, onSelect }) => (
  <div className='my-3 scroll-m-2 rounded-lg p-3 bg-gray-50 shadow-sm' onClick={() => onSelect(song)}>
      <p className='text-gray-700'>{song.name}</p>
      <h3 className='text-gray-400'>{song.author} ({song.year})</h3>
  </div>
);

export default SongItem;

