// src/services/authService.js
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "/api/auth";
const credentials = {
  email: "frontend@mgs.com",
  password: "C8#zQg82SCgLoC^44Hga",
};

const getAuthToken = async (forceRefresh = false) => {
  if (!forceRefresh) {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) return storedToken;
  }

  try {
    const response = await axios.post(API_URL, credentials);
    localStorage.setItem("authToken", response.data.accessToken);
    return response.data.accessToken;
  } catch (error) {
    console.error("Failed to fetch auth token:", error);
    throw error;
  }
};

const refreshAuthToken = async () => {
  try {
    const newToken = await getAuthToken(true);
    return newToken;
  } catch (error) {
    console.error("Failed to refresh auth token:", error);
    throw error;
  }
};

export default {
  getAuthToken,
  refreshAuthToken,
};
