// src/services/paymentService.js
import axios from "axios";
import authService from "./authService";

const PAYMENT_API_URL = process.env.REACT_APP_API_URL + "/lynk/pay";

const makePayment = async (paymentDetails) => {
  let token = await authService.getAuthToken();

  try {
    const response = await axios.post(PAYMENT_API_URL, paymentDetails, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 503)
    ) {
      // Token might be expired or service unavailable, refresh the token
      try {
        token = await authService.refreshAuthToken();
        const retryResponse = await axios.post(
          PAYMENT_API_URL,
          paymentDetails,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return retryResponse.data;
      } catch (retryError) {
        console.error("Retry after token refresh failed:", retryError);
        throw retryError;
      }
    } else {
      throw error;
    }
  }
};

export default {
  makePayment,
};
