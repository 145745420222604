import axios from "axios";
import authService from "./authService";

const API_URL = process.env.REACT_APP_API_URL + "/api/whatsapp";

const requestVerificationCode = async (phoneNumber) => {
  let token = await authService.getAuthToken();

  const url = `${API_URL}/requestVerification/${phoneNumber}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 503)
    ) {
      // Token might be expired or service unavailable, refresh the token
      try {
        token = await authService.refreshAuthToken();
        const retryResponse = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return retryResponse.data;
      } catch (retryError) {
        console.error("Retry after token refresh failed:", retryError);
        throw retryError;
      }
    } else {
      throw error;
    }
  }
};

const verifyCode = async (phoneNumber, code) => {
  let token = await authService.getAuthToken();

  const url = `${API_URL}/verify`;

  const requestData = {
    phoneNumber: phoneNumber,
    code: code,
  };

  try {
    const response = await axios.post(url, requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 503)
    ) {
      // Token might be expired or service unavailable, refresh the token
      try {
        token = await authService.refreshAuthToken();
        const retryResponse = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return retryResponse.data;
      } catch (retryError) {
        console.error("Retry after token refresh failed:", retryError);
        throw retryError;
      }
    } else {
      throw error;
    }
  }
};

export default {
  requestVerificationCode,
  verifyCode,
};
